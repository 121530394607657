.cell_content div {
	display: -webkit-box;
	max-width: 50vw;
	/* -webkit-line-clamp: 2; */
	/* -webkit-box-orient: vertical; */
	overflow: hidden;
	text-overflow: ellipsis;
}

.cell_url * {
	text-decoration: none;
	color: #7b809a;
	font-weight: bold;
}

.resultsImportTable {
	width: 100%;
	text-align: left;
	border-spacing: 1rem 0.5rem;
	padding: 5px;
	background-color: #f1f1f1;
	border-radius: 10px;
	margin-top: 10px;
}

.importFullCell {
	width: 100%;
}
