.faiq-in-message table {
	width: 100%;
	border-collapse: collapse;
	font-family: Arial, sans-serif;
}

.faiq-in-message th {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

.faiq-in-message td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

.faiq-in-message th {
	background-color: #f2f2f2;
	font-weight: bold;
}
.faiq-in-message tr:nth-child(even) {
	background-color: #f9f9f9;
}

.faiq-in-message td {
	padding: 5px;
}
