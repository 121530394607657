.chat-bubble {
	background-color: #e6f8f1;
	padding: 16px 28px;
	-webkit-border-radius: 20px;
	-webkit-border-bottom-left-radius: 2px;
	-moz-border-radius: 20px;
	-moz-border-radius-bottomleft: 2px;
	border-radius: 20px;
	border-bottom-left-radius: 2px;
	display: inline-block;
}
.typing {
	align-items: center;
	display: flex;
	height: 17px;
}
.typing .dot {
	animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
	background-color: #8f8f8f;
	/* //rgba(20,105,69,.7); */
	border-radius: 50%;
	height: 7px;
	margin-right: 4px;
	vertical-align: middle;
	width: 7px;
	display: inline-block;
}
.typing .dot:nth-child(1) {
	animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
	animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
	animation-delay: 400ms;
}
.typing .dot:last-child {
	margin-right: 0;
}

@keyframes mercuryTypingAnimation {
	0% {
		transform: translateY(0px);
		background-color: #2a2a2a;
		/* // rgba(20,105,69,.7); */
	}
	28% {
		transform: translateY(-7px);
		background-color: #414141;
		/* //rgba(20,105,69,.4); */
	}
	44% {
		transform: translateY(0px);
		background-color: #767676;
		/* //rgba(20,105,69,.2); */
	}
}

.sourceChatTitle {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sourceChatDescription {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
